import { Box, Center, Image } from "@chakra-ui/react";
import { Link, Outlet } from "react-router-dom";
import logo from "./../assets/logo.png";

export default function LayoutAuth() {
  return (
    <>
      <header>
        <Box minH={"200px"} bg={"green.400"} justifyContent={"center"}>
          <Center p={6}>
            <Link to="/">
              <Image src={logo} alt="Logo" />
            </Link>
          </Center>
        </Box>
      </header>
      <Outlet />
    </>
  );
}
