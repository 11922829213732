import { Route, Routes } from "react-router-dom";
import LayoutAuth from "../layouts/layoutAuth";
import LayoutMain from "../layouts/layoutMain";
import { TermsAndConditionsPage } from "../pages/TermsAndConditionsPage";
import { AboutPage } from "./../pages/AboutPage";
import { BlogListPage } from "./../pages/BlogListPage";
import { BlogViewPage } from "./../pages/BlogViewPage";
import { HomePage } from "./../pages/HomePage";
import { ShopListPage } from "./../pages/ShopListPage";
import { ShopViewPage } from "./../pages/ShopViewPage";
import { ForgotPasswordPage } from "./../pages/auth/ForgotPasswordPage";
import { LoginPage } from "./../pages/auth/LoginPage";
import { RegisterPage } from "./../pages/auth/RegisterPage";
import { FaqPage } from "../pages/FaqPage";

export function RouteIndex() {
  return (
    <>
      <Routes>
        <Route element={<LayoutAuth />}>
          <Route path="login" element={<LoginPage />} />
          <Route path="register" element={<RegisterPage />} />
          <Route path="forgot-password" element={<ForgotPasswordPage />} />
        </Route>

        <Route element={<LayoutMain />}>
          <Route index element={<HomePage />} />
          <Route path="about" element={<AboutPage />} />
          <Route
            path="terms-and-conditions"
            element={<TermsAndConditionsPage />}
          />
          <Route path="faq" element={<FaqPage />} />

          <Route path="blog" element={<BlogListPage />} />
          <Route path="blog/:blogSlug" element={<BlogViewPage />} />

          <Route path="shop" element={<ShopListPage />} />
          <Route path="shop/:shopId" element={<ShopViewPage />} />
        </Route>
      </Routes>
    </>
  );
}
