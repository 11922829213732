import * as joseJwt from "jose";
const secret_key = process.env.REACT_APP_KEY;

export default async function JwtToken(jsonData?: any) {
  //   const jsonData = { foo: "bar" };
  const secret = new TextEncoder().encode(secret_key);
  const alg = "HS256";

  const jwt = await new joseJwt.SignJWT(jsonData)
    .setProtectedHeader({ alg })
    .setIssuedAt()
    .setIssuer("MY_SHOP")
    .setAudience("MY_SHOP")
    .setExpirationTime("1m")
    .sign(secret);

  return jwt;
}
