import { Box, Button, Center, Flex, Text } from "@chakra-ui/react";
import { Link } from "react-router-dom";

export const MyLink = ({ name, bgColor, hrefUrl, linkIcon, clkEvent }: any) => {
  return (
    <>
      <Link to={`${hrefUrl}`} onClick={() => clkEvent()}>
        <Box
          p={2}
          px={8}
          rounded={"full"}
          bg={`${bgColor}.400`}
          color={"#fff"}
          _hover={{ bg: `${bgColor}.600` }}
        >
          <Flex justify={"center"}>
            <Center>
              {linkIcon ? (
                <Text fontSize={"medium"} pr={2}>
                  {linkIcon}
                </Text>
              ) : (
                ""
              )}
              <Text>{name}</Text>
            </Center>
          </Flex>
        </Box>
      </Link>
    </>
  );
};

export const MyLeftLink = ({ name, hrefUrl, linkIcon, clkEvent }: any) => {
  return (
    <Link to={hrefUrl} onClick={() => clkEvent()}>
      <Flex
        align={"center"}
        px={4}
        py={3}
        _hover={{ bg: "gray.200", textDecoration: "none" }}
        borderBottom={"1px solid LightGray"}
      >
        {linkIcon}
        <Text ps={1}>{name}</Text>
      </Flex>
    </Link>
  );
};

export const MyButton = ({
  name,
  bgColor,
  btnType,
  btnIcon,
  clkEvent,
}: any) => {
  return (
    <>
      <Button
        type={btnType ? btnType : "button"}
        onClick={() => clkEvent()}
        leftIcon={btnIcon ? btnIcon : ""}
        colorScheme={bgColor}
        variant="solid"
        rounded={"full"}
      >
        {name}
      </Button>
    </>
  );
};
