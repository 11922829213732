import { Box, Button, Center, Stack, Text } from "@chakra-ui/react";
import { BiSolidUser } from "react-icons/bi";

import { List, ListIcon, ListItem, useColorModeValue } from "@chakra-ui/react";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Autoplay, Pagination } from "swiper/modules";

export default function SlideHome() {
  return (
    <>
      <Swiper
        spaceBetween={30}
        autoplay={{
          delay: 5500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        modules={[Autoplay, Pagination]}
        className="mySwiper"
      >
        <SwiperSlide>
          <Center p={6}>
            <Box
              w={"full"}
              bg={useColorModeValue("white", "gray.800")}
              boxShadow={"lg"}
              rounded={"2xl"}
              overflow={"hidden"}
            >
              <Stack
                textAlign={"center"}
                p={6}
                color={useColorModeValue("gray.800", "white")}
                align={"center"}
              >
                <Text
                  fontSize={"sm"}
                  fontWeight={500}
                  bg={useColorModeValue("green.50", "green.900")}
                  p={2}
                  px={3}
                  color={"green.500"}
                  rounded={"full"}
                >
                  Hobby
                </Text>
                <Stack direction={"row"} align={"center"} justify={"center"}>
                  <Text fontSize={"6xl"} fontWeight={800}>
                    Free
                  </Text>
                </Stack>
              </Stack>
              <Box bg={useColorModeValue("gray.50", "gray.900")} px={8} py={10}>
                <List spacing={3}>
                  <ListItem>
                    <ListIcon as={BiSolidUser} color="green.400" />
                    5.000 page views
                  </ListItem>
                  <ListItem>
                    <ListIcon as={BiSolidUser} color="green.400" />
                    50 automation executions
                  </ListItem>
                  <ListItem>
                    <ListIcon as={BiSolidUser} color="green.400" />
                    50 identified users
                  </ListItem>
                  <ListItem>
                    <ListIcon as={BiSolidUser} color="green.400" />
                    All features
                  </ListItem>
                </List>

                <Button
                  mt={10}
                  w={"full"}
                  bg={"green.400"}
                  color={"white"}
                  rounded={"xl"}
                  boxShadow={"0 5px 20px 0px rgb(72 187 120 / 43%)"}
                  _hover={{
                    bg: "green.500",
                  }}
                  _focus={{
                    bg: "green.500",
                  }}
                >
                  Try Now
                </Button>
              </Box>
            </Box>
          </Center>
        </SwiperSlide>
        <SwiperSlide>
          <Center p={6}>
            <Box
              w={"full"}
              bg={useColorModeValue("white", "gray.800")}
              boxShadow={"lg"}
              rounded={"2xl"}
              overflow={"hidden"}
            >
              <Stack
                textAlign={"center"}
                p={6}
                color={useColorModeValue("gray.800", "white")}
                align={"center"}
              >
                <Text
                  fontSize={"sm"}
                  fontWeight={500}
                  bg={useColorModeValue("green.50", "green.900")}
                  p={2}
                  px={3}
                  color={"green.500"}
                  rounded={"full"}
                >
                  Hobby
                </Text>
                <Stack direction={"row"} align={"center"} justify={"center"}>
                  <Text fontSize={"3xl"}>$</Text>
                  <Text fontSize={"6xl"} fontWeight={800}>
                    79
                  </Text>
                  <Text color={"gray.500"}>/month</Text>
                </Stack>
              </Stack>
              <Box bg={useColorModeValue("gray.50", "gray.900")} px={6} py={10}>
                <List spacing={3}>
                  <ListItem>
                    <ListIcon as={BiSolidUser} color="green.400" />
                    5.000 page views
                  </ListItem>
                  <ListItem>
                    <ListIcon as={BiSolidUser} color="green.400" />
                    50 automation executions
                  </ListItem>
                  <ListItem>
                    <ListIcon as={BiSolidUser} color="green.400" />
                    50 identified users
                  </ListItem>
                  <ListItem>
                    <ListIcon as={BiSolidUser} color="green.400" />
                    All features
                  </ListItem>
                </List>

                <Button
                  mt={10}
                  w={"full"}
                  bg={"green.400"}
                  color={"white"}
                  rounded={"xl"}
                  boxShadow={"0 5px 20px 0px rgb(72 187 120 / 43%)"}
                  _hover={{
                    bg: "green.500",
                  }}
                  _focus={{
                    bg: "green.500",
                  }}
                >
                  Start your trial
                </Button>
              </Box>
            </Box>
          </Center>
        </SwiperSlide>
      </Swiper>
    </>
  );
}
