import {
  AspectRatio,
  Box,
  Container,
  Flex,
  SimpleGrid,
  Skeleton,
  SkeletonText,
  Stack,
} from "@chakra-ui/react";

export function LoadingBlogList() {
  return (
    <>
      <Box
        maxW={"300px"}
        w={"full"}
        boxShadow={"2xl"}
        rounded={"md"}
        p={6}
        overflow={"hidden"}
        bg={"#fff"}
      >
        <Box bg={"gray.100"} mt={-6} mx={-6} mb={6} pos={"relative"}>
          <Skeleton h={"200px"}></Skeleton>
        </Box>
        <Stack>
          <Skeleton height="18px" startColor="green.200" endColor="green.200">
            <Box w={"230px"}></Box>
          </Skeleton>
          <Skeleton height="30px"></Skeleton>
          <Skeleton height="30px"></Skeleton>
        </Stack>
        <Box mt={6}>
          <Skeleton height="18px"></Skeleton>
        </Box>
      </Box>
    </>
  );
}
export function LoadingBlogView() {
  return (
    <>
      <Skeleton>
        <AspectRatio ratio={21 / 9}>
          <Box h={{ base: "100%", sm: "400px", lg: "500px" }}></Box>
        </AspectRatio>
      </Skeleton>
      <Box py={8} textAlign={"left"}>
        <Container maxW="container.lg">
          <SimpleGrid spacing={6} py={6} textAlign={"justify"}>
            <Skeleton height="30px"></Skeleton>
            <Skeleton height="18px" w={"150px"}></Skeleton>
            <SkeletonText noOfLines={4} spacing="2" skeletonHeight="2" />
            <SkeletonText noOfLines={2} spacing="2" skeletonHeight="2" />
          </SimpleGrid>
        </Container>
      </Box>
    </>
  );
}
