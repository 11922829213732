import { Avatar, Box, Flex, Skeleton } from "@chakra-ui/react";

export function LoadingCardShopList() {
  return (
    <Flex bg={"white"} boxShadow={"md"} rounded={"md"}>
      <Box p={2}>
        <Skeleton>
          <Avatar
            size="lg"
            name="Loading..."
            rounded={"md"}
            borderRadius={"md"}
          />
        </Skeleton>
      </Box>
      <Flex direction={"column"} justify={"space-evenly"} p={2}>
        <Skeleton height="18px">
          <Box w={"230px"}></Box>
        </Skeleton>
        <Skeleton height="14px">
          <Box w={"230px"}></Box>
        </Skeleton>
      </Flex>
    </Flex>
  );
}
