import {
  Box,
  Center,
  Container,
  Heading,
  List,
  ListItem,
  Stack,
  Text,
} from "@chakra-ui/react";

import { Flex } from "@chakra-ui/react";

import { aboutTitleTag } from "../_data/headerTitleList";
import { HelmetTitleTag } from "../components/helmetTitleTag";

const contact_us_email: any = process.env.REACT_APP_CONTACT_US_EMAIL;

export function FaqPage() {
  return (
    <>
      <HelmetTitleTag title={aboutTitleTag.title} desc={aboutTitleTag.desc} />
      <section>
        <Box minH={"150px"} bg={"green.400"} justifyContent={"center"}>
          <Center p={6}>
            <Heading textAlign={"center"}>
              Frequently Asked Questions (FAQs)
            </Heading>
          </Center>
        </Box>
        <Box mt={"-50px"} bg={"#fff"} roundedTop={"2xl"}>
          <Container maxW={"container.sm"}>
            <Stack direction={"column"} spacing="20px" py={8}>
              <Text align={"justify"}>
                Welcome to Food Cart&apos;s FAQ section! Here, we aim to address
                common queries and provide comprehensive answers to ensure you
                have a seamless experience with our self-ordering system. If you
                have any specific concerns or additional questions, feel free to
                contact us.
              </Text>

              <List>
                <ListItem>
                  <Text fontWeight={"800"} py={4}>
                    1. General Questions
                  </Text>
                </ListItem>
                <Flex gap={4} direction={"column"}>
                  <ListItem>
                    1.1. What is Food Cart?
                    <br />
                    Food Cart is an innovative self-ordering system designed to
                    provide a hassle-free and enjoyable food ordering
                    experience. We connect users with local stores, allowing
                    them to discover new culinary delights and support local
                    businesses.
                  </ListItem>
                  <ListItem>
                    1.2. How do I access Food Cart?
                    <br />
                    To access Food Cart, simply visit our website or use our
                    mobile app. Create an account, explore local menus,
                    customize your orders, and enjoy the convenience of having
                    your favorite meals delivered to your doorstep.
                  </ListItem>
                  <ListItem>
                    1.3. Is there a fee for using Food Cart?
                    <br />
                    No, using Food Cart is free for users. You only pay for the
                    cost of the food and any applicable delivery fees. We
                    believe in providing a user-friendly platform without
                    additional charges.
                  </ListItem>
                </Flex>
                <ListItem>
                  <Text fontWeight={"800"} py={4}>
                    2. Terms and Conditions
                  </Text>
                </ListItem>
                <Flex gap={4} direction={"column"}>
                  <ListItem>
                    2.1. Why do I need to agree to the Terms and Conditions?
                    <br />
                    Agreeing to our Terms and Conditions is a standard practice
                    that outlines the rules and regulations for using Food
                    Cart&apos;s self-ordering system. It ensures that both users
                    and Food Cart understand their rights and responsibilities.
                  </ListItem>
                  <ListItem>
                    2.2. Can I still use Food Cart if I don&apos;t agree with
                    the Terms and Conditions?
                    <br />
                    Unfortunately, you cannot use Food Cart&apos;s services if
                    you do not agree to our Terms and Conditions. By using our
                    platform, you acknowledge and accept the outlined rules.
                  </ListItem>
                  <ListItem>
                    2.3. How often are the Terms and Conditions updated?
                    <br />
                    Food Cart reserves the right to revise the Terms and
                    Conditions at any time. Any changes made will be effective
                    immediately. Users are encouraged to check the Terms and
                    Conditions regularly for updates.
                  </ListItem>
                </Flex>
                <ListItem>
                  <Text fontWeight={"800"} py={4}>
                    3. Usage of Our Platform
                  </Text>
                </ListItem>
                <Flex gap={4} direction={"column"}>
                  <ListItem>
                    3.1. Can I trust the information provided on Food
                    Cart&apos;s platform?
                    <br />
                    The information on our platform is intended for general use
                    and may be subject to change without notice. While we strive
                    to provide accurate information, users should use the
                    platform at their own risk and verify details with the
                    respective local stores.
                  </ListItem>
                  <ListItem>
                    3.2. What materials on the platform are subject to
                    copyright?
                    <br />
                    The design, layout, look, appearance, and graphics on the
                    Food Cart platform are owned by or licensed to us and are
                    subject to copyright. Reproduction of these materials is
                    prohibited without adhering to the copyright notice.
                  </ListItem>
                  <ListItem>
                    3.3. How do I report inaccurate information on the platform?
                    <br />
                    If you come across inaccurate information on the platform,
                    please contact us immediately. We value user feedback and
                    will address any discrepancies promptly.
                  </ListItem>
                </Flex>
                <ListItem>
                  <Text fontWeight={"800"} py={4}>
                    4. Ordering Process
                  </Text>
                </ListItem>
                <Flex gap={4} direction={"column"}>
                  <ListItem>
                    4.1. How does the self-ordering system work?
                    <br />
                    Our self-ordering system allows users to browse local menus,
                    customize their orders, and place them directly through the
                    platform. Once the order is confirmed, it is sent to the
                    participating local store for processing.
                  </ListItem>
                  <ListItem>
                    4.2. Are there any limitations to the self-ordering system?
                    <br />
                    While the self-ordering system is designed for a smooth
                    experience, users are responsible for ensuring the accuracy
                    of their orders. Any issues arising from inaccurate
                    information during the ordering process are not the
                    responsibility of Food Cart.
                  </ListItem>
                  <ListItem>
                    4.3. How do I know if my order is confirmed?
                    <br />
                    Upon placing an order, you will receive a confirmation
                    notification on the platform. Additionally, participating
                    local stores will confirm the availability of your order.
                    Please ensure you provide accurate contact information for
                    communication purposes.
                  </ListItem>
                </Flex>
                <ListItem>
                  <Text fontWeight={"800"} py={4}>
                    5. Privacy
                  </Text>
                </ListItem>
                <Flex gap={4} direction={"column"}>
                  <ListItem>
                    5.1. How is my privacy protected when using Food Cart?
                    <br />
                    Your privacy is crucial to us. We follow strict privacy
                    practices outlined in our Privacy Policy. We recommend
                    reviewing the Privacy Policy to understand how your personal
                    information is collected, used, and disclosed.
                  </ListItem>
                  <ListItem>
                    5.2. Is my personal information secure on Food Cart&apos;s
                    platform?
                    <br />
                    Yes, we employ industry-standard security measures to
                    protect your personal information. Our platform uses
                    encryption and secure protocols to ensure the
                    confidentiality of your data.
                  </ListItem>
                  <ListItem>
                    5.3. Can I opt-out of sharing certain personal information?
                    <br />
                    While some personal information is necessary for using our
                    platform, users can manage certain privacy settings within
                    their accounts. Refer to the Privacy Policy for more
                    details.
                  </ListItem>
                </Flex>
                <ListItem>
                  <Text fontWeight={"800"} py={4}>
                    6. Intellectual Property
                  </Text>
                </ListItem>
                <Flex gap={4} direction={"column"}>
                  <ListItem>
                    6.1. What trademarks are acknowledged on the Food Cart
                    platform?
                    <br />
                    All trademarks reproduced on the Food Cart platform, not
                    owned or licensed to us, are acknowledged. We respect
                    intellectual property rights and give proper credit to
                    trademarks associated with local stores.
                  </ListItem>
                  <ListItem>
                    6.2. Can I use content from the Food Cart platform for
                    personal purposes?
                    <br />
                    Reproduction of materials on the Food Cart platform is
                    prohibited without complying with the copyright notice.
                    Users are not permitted to use, reproduce, or distribute
                    platform content for personal purposes without proper
                    authorization.
                  </ListItem>
                  <ListItem>
                    6.3. How can I report copyright infringement on the
                    platform?
                    <br />
                    If you believe there has been a copyright infringement on
                    the Food Cart platform, please contact us immediately with
                    relevant details. We take intellectual property matters
                    seriously and will investigate and address any reported
                    infringements.
                  </ListItem>
                </Flex>
                <ListItem>
                  <Text fontWeight={"800"} py={4}>
                    7. Changes to Terms and Conditions
                  </Text>
                </ListItem>
                <Flex gap={4} direction={"column"}>
                  <ListItem>
                    7.1. How will I be informed about changes to the Terms and
                    Conditions?
                    <br />
                    Any changes to the Terms and Conditions will be effective
                    immediately and communicated through the platform. Users are
                    encouraged to check the Terms and Conditions regularly for
                    updates.
                  </ListItem>
                  <ListItem>
                    7.2. Can I opt-out of receiving notifications about changes
                    to the Terms and Conditions?
                    <br />
                    As changes to the Terms and Conditions are critical for your
                    continued use of the platform, users cannot opt-out of
                    receiving notifications about these updates. It is your
                    responsibility to stay informed about any changes.
                  </ListItem>
                  <ListItem>
                    7.3. Can I provide feedback on proposed changes to the Terms
                    and Conditions?
                    <br />
                    While we value user feedback, changes to the Terms and
                    Conditions are typically non-negotiable. If you have
                    specific concerns, please contact us, and we will do our
                    best to address them.
                  </ListItem>
                </Flex>
                <ListItem>
                  <Text fontWeight={"800"} py={4}>
                    8. User Responsibilities
                  </Text>
                </ListItem>
                <Flex gap={4} direction={"column"}>
                  <ListItem>
                    8.1. What responsibilities do I have as a user?
                    <br />
                    Users are responsible for providing accurate information
                    during the ordering process, maintaining the confidentiality
                    of their account information, and complying with the Terms
                    and Conditions outlined by Food Cart.
                  </ListItem>
                  <ListItem>
                    8.2. How can I ensure the security of my account?
                    <br />
                    To ensure the security of your account, use a strong, unique
                    password and avoid sharing your login credentials. If you
                    suspect unauthorized access, change your password
                    immediately and contact us.
                  </ListItem>
                  <ListItem>
                    8.3. What happens if I forget my password?
                    <br />
                    If you forget your password, use the &quot;Forgot
                    Password&quot; option on the platform to reset it. Follow
                    the provided instructions to regain access to your account.
                  </ListItem>
                </Flex>
                <ListItem>
                  <Text fontWeight={"800"} py={4}>
                    9. Limitation of Liability
                  </Text>
                </ListItem>
                <Flex gap={4} direction={"column"}>
                  <ListItem>
                    9.1. What liability does Food Cart have?
                    <br />
                    Food Cart shall not be liable for any indirect,
                    consequential, or incidental damages arising from the use
                    of, or inability to use, the platform. Users use the
                    platform at their own risk, and Food Cart is not responsible
                    for issues related to the quality of the products or
                    services provided by local stores.
                  </ListItem>
                </Flex>
                <ListItem>
                  <Text fontWeight={"800"} py={4}>
                    10. Governing Law
                  </Text>
                </ListItem>
                <Flex gap={4} direction={"column"}>
                  <ListItem>
                    10.1. What laws govern this Agreement?
                    <br />
                    This Agreement is governed by and construed in accordance
                    with the laws of Malaysia. Any legal disputes or claims
                    arising out of or related to this Agreement will be subject
                    to the jurisdiction of the courts in Malaysia.
                  </ListItem>
                  <ListItem>
                    10.2. How does the governing law affect users?
                    <br />
                    Users are expected to comply with the laws of the
                    jurisdiction specified
                  </ListItem>
                </Flex>
              </List>
            </Stack>
          </Container>
        </Box>
      </section>
    </>
  );
}
