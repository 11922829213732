import {
  Box,
  Container,
  Flex,
  FormControl,
  FormHelperText,
  Heading,
  Input,
  Stack,
  Text,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { loginTitleTag } from "../../_data/headerTitleList";
import { HelmetTitleTag } from "../../components/helmetTitleTag";
import { MyButton } from "./../../components/MyButtons";

export function LoginPage() {
  return (
    <>
      <HelmetTitleTag title={loginTitleTag.title} desc={loginTitleTag.desc} />
      <Box mt={"-50px"} bg={"#fff"} roundedTop={"2xl"}>
        <Container maxW={"container.sm"}>
          <Stack direction={"column"}>
            <Stack direction={"column"} py={6} mx={4}>
              <Heading textAlign={"center"}>
                Welcome back!
                <Text fontSize={"md"} fontWeight={"400"} color={"gray.400"}>
                  Please fill in the field below to log in
                </Text>
              </Heading>
            </Stack>
            <Stack direction={"column"} spacing="2">
              <FormControl>
                <Input type="email" size="lg" placeholder="Email" />
                <FormHelperText></FormHelperText>
              </FormControl>
              <FormControl>
                <Input type="password" size="lg" placeholder="Password" />
                <FormHelperText></FormHelperText>
              </FormControl>
              <FormControl>
                <Flex justify={"space-between"}>
                  <MyButton
                    name="Log in"
                    bgColor={"green"}
                    clkEvent={() => console.log("form submit!")}
                  />
                  <Link to="/forgot-password">
                    <Box
                      pt={2}
                      color={"green.400"}
                      _hover={{ textDecoration: "underline" }}
                    >
                      Forgot Password?
                    </Box>
                  </Link>
                </Flex>
              </FormControl>
            </Stack>
          </Stack>
        </Container>
      </Box>
      <Box pos="fixed" w={"100%"} bottom="0" textAlign={"center"} py={8}>
        <Container maxW={"container.sm"}>
          <Stack direction={"column"} spacing={"15px"}>
            <Link to="/register">
              <Box
                border={"1px solid gray"}
                color={"gray"}
                px={4}
                py={2}
                rounded={"full"}
                _hover={{ bg: "gray.200" }}
              >
                Create an account
              </Box>
            </Link>
          </Stack>
        </Container>
      </Box>
    </>
  );
}
