import {
  Box,
  Center,
  Container,
  Heading,
  List,
  ListItem,
  Stack,
  Text,
} from "@chakra-ui/react";

import { Flex } from "@chakra-ui/react";

import { aboutTitleTag } from "../_data/headerTitleList";
import { HelmetTitleTag } from "../components/helmetTitleTag";

const contact_us_email: any = process.env.REACT_APP_CONTACT_US_EMAIL;

export function TermsAndConditionsPage() {
  return (
    <>
      <HelmetTitleTag title={aboutTitleTag.title} desc={aboutTitleTag.desc} />
      <section>
        <Box minH={"150px"} bg={"green.400"} justifyContent={"center"}>
          <Center p={6}>
            <Heading textAlign={"center"}>Terms and Conditions</Heading>
          </Center>
        </Box>
        <Box mt={"-50px"} bg={"#fff"} roundedTop={"2xl"}>
          <Container maxW={"container.sm"}>
            <Stack direction={"column"} spacing="20px" py={8}>
              <Text align={"justify"}>Welcome to Food Cart!</Text>
              <Text align={"justify"}>
                These terms and conditions (the &quot;Agreement&quot;) outline
                the rules and regulations for the use of Food Cart&apos;s
                self-ordering system and services. By accessing this website or
                utilizing our self-ordering platform, you acknowledge and agree
                to comply with these terms and conditions. If you do not accept
                all of the terms and conditions stated herein, please refrain
                from using Food Cart&apos;s services.
              </Text>

              <List>
                <ListItem>
                  <Text fontWeight={"800"} py={4}>
                    1. Usage of Our Platform
                  </Text>
                </ListItem>
                <Flex gap={4} direction={"column"}>
                  <ListItem>
                    1.1. The content provided on the pages of this platform is
                    for your general information and use only. We reserve the
                    right to change, modify, or update the content without
                    notice.
                  </ListItem>
                  <ListItem>
                    1.2. Your use of any information or materials on this
                    platform is at your own risk. Food Cart shall not be liable
                    for any consequences arising from the use of the platform.
                    It is your responsibility to ensure that any products,
                    services, or information available through this platform
                    meet your specific requirements.
                  </ListItem>
                  <ListItem>
                    1.3. This platform contains material owned by or licensed to
                    Food Cart, including but not limited to design, layout,
                    look, appearance, and graphics. Reproduction is prohibited
                    except in accordance with the copyright notice, which is
                    part of these terms and conditions.
                  </ListItem>
                </Flex>
                <ListItem>
                  <Text fontWeight={"800"} py={4}>
                    2. Ordering Process
                  </Text>
                </ListItem>
                <Flex gap={4} direction={"column"}>
                  <ListItem>
                    2.1. Our self-ordering system is designed to offer a smooth
                    and enjoyable food ordering experience. Users are
                    responsible for ensuring the accuracy of their orders. Any
                    issues arising from inaccurate information provided during
                    the ordering process will not be the responsibility of Food
                    Cart.
                  </ListItem>
                  <ListItem>
                    2.2. Orders placed through our platform are subject to
                    availability and confirmation by the participating local
                    stores.
                  </ListItem>
                </Flex>
                <ListItem>
                  <Text fontWeight={"800"} py={4}>
                    3. Privacy
                  </Text>
                </ListItem>
                <Flex gap={4} direction={"column"}>
                  <ListItem>
                    3.1. Your use of our platform is governed by our Privacy
                    Policy. We encourage you to review our Privacy Policy to
                    understand the practices regarding the collection, use, and
                    disclosure of your personal information.
                  </ListItem>
                </Flex>
                <ListItem>
                  <Text fontWeight={"800"} py={4}>
                    4. Intellectual Property
                  </Text>
                </ListItem>
                <Flex gap={4} direction={"column"}>
                  <ListItem>
                    4.1. All trademarks reproduced on this platform, which are
                    not the property of or licensed to Food Cart, are
                    acknowledged on the platform.
                  </ListItem>
                </Flex>
                <ListItem>
                  <Text fontWeight={"800"} py={4}>
                    5. Changes to Terms and Conditions
                  </Text>
                </ListItem>
                <Flex gap={4} direction={"column"}>
                  <ListItem>
                    5.1. Food Cart reserves the right to revise these terms and
                    conditions at any time. Changes will be effective
                    immediately upon posting. Your continued use of the platform
                    after any changes signify your acceptance of the revised
                    terms.
                  </ListItem>
                </Flex>
                <ListItem>
                  <Text fontWeight={"800"} py={4}>
                    6. User Responsibilities
                  </Text>
                </ListItem>
                <Flex gap={4} direction={"column"}>
                  <ListItem>
                    6.1. Users are required to provide accurate and complete
                    information during the ordering process.
                  </ListItem>
                  <ListItem>
                    6.2. Users are responsible for maintaining the
                    confidentiality of their account information, including
                    passwords.
                  </ListItem>
                </Flex>
                <ListItem>
                  <Text fontWeight={"800"} py={4}>
                    7. Limitation of Liability
                  </Text>
                </ListItem>
                <Flex gap={4} direction={"column"}>
                  <ListItem>
                    7.1. Food Cart shall not be liable for any indirect,
                    consequential, or incidental damages arising out of the use
                    of, or inability to use, the platform.
                  </ListItem>
                </Flex>
                <ListItem>
                  <Text fontWeight={"800"} py={4}>
                    8. Governing Law
                  </Text>
                </ListItem>
                <Flex gap={4} direction={"column"}>
                  <ListItem>
                    8.1. This Agreement is governed by and construed in
                    accordance with the laws of Malaysia.
                  </ListItem>
                </Flex>
                <ListItem>
                  <Text fontWeight={"800"} py={4}>
                    9. Contact Information
                  </Text>
                </ListItem>
                <Flex gap={4} direction={"column"}>
                  <ListItem>
                    9.1. For any questions or concerns regarding these terms and
                    conditions, please contact us at {contact_us_email}.
                  </ListItem>
                </Flex>
              </List>

              <Text align={"justify"}>
                By using Food Cart&apos;s self-ordering system, you agree to abide by
                these terms and conditions. Thank you for choosing Food Cart. We
                look forward to providing you with an exceptional food ordering
                experience.
              </Text>
            </Stack>
          </Container>
        </Box>
      </section>
    </>
  );
}
