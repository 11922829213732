import {
  Box,
  Container,
  Flex,
  FormControl,
  FormHelperText,
  Heading,
  Input,
  Stack,
  Text,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { registerTitleTag } from "../../_data/headerTitleList";
import { HelmetTitleTag } from "../../components/helmetTitleTag";
import { MyButton } from "./../../components/MyButtons";

export function RegisterPage() {
  return (
    <>
      <HelmetTitleTag
        title={registerTitleTag.title}
        desc={registerTitleTag.desc}
      />
      <Box mt={"-50px"} bg={"#fff"} roundedTop={"2xl"}>
        <Container maxW={"container.sm"}>
          <Stack direction={"column"}>
            <Stack direction={"column"} py={6} mx={4}>
              <Heading textAlign={"center"}>
                Create your account
                <Text fontSize={"md"} fontWeight={"400"} color={"gray.400"}>
                  Please fill in the field below to register
                </Text>
              </Heading>
            </Stack>
            <Stack direction={"column"} spacing="2">
              <FormControl>
                <Input type="text" size="lg" placeholder="Name" />
                <FormHelperText></FormHelperText>
              </FormControl>
              <FormControl>
                <Input type="email" size="lg" placeholder="Email" />
                <FormHelperText></FormHelperText>
              </FormControl>
              <FormControl>
                <Input type="password" size="lg" placeholder="Password" />
                <FormHelperText></FormHelperText>
              </FormControl>
              <FormControl>
                <Flex justify={"space-between"}>
                  <MyButton
                    name="Register"
                    bgColor={"green"}
                    clkEvent={() => console.log("form submit!")}
                  />
                </Flex>
              </FormControl>
            </Stack>
          </Stack>
        </Container>
      </Box>
      <Box pos="fixed" w={"100%"} bottom="0" textAlign={"center"} py={8}>
        <Container maxW={"container.sm"}>
          <Stack direction={"column"} spacing={"15px"}>
            <Link to="/login">
              <Box
                border={"1px solid gray"}
                color={"gray"}
                px={4}
                py={2}
                rounded={"full"}
                _hover={{ bg: "gray.200" }}
              >
                Log in
              </Box>
            </Link>
          </Stack>
        </Container>
      </Box>
    </>
  );
}
