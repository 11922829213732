import {
  Box,
  Button,
  Center,
  Container,
  Heading,
  Highlight,
  Input,
  Stack,
  Text,
} from "@chakra-ui/react";

import { Flex } from "@chakra-ui/react";

import { Link } from "react-router-dom";
import { aboutTitleTag } from "../_data/headerTitleList";
import { HelmetTitleTag } from "../components/helmetTitleTag";

const contact_us_email: any = process.env.REACT_APP_CONTACT_US_EMAIL;

export function AboutPage() {
  return (
    <>
      <HelmetTitleTag title={aboutTitleTag.title} desc={aboutTitleTag.desc} />
      <section>
        <Box minH={"130px"} bg={"green.400"} justifyContent={"center"}>
          <Center p={6}>
            <Heading textAlign={"center"}>About us</Heading>
          </Center>
        </Box>
        <Box mt={"-50px"} bg={"#fff"} roundedTop={"2xl"}>
          <Container maxW={"container.sm"}>
            <Stack direction={"column"} spacing="20px" py={8}>
              <Text align={"justify"}>
                Welcome to our world of hassle-free and delightful food
                ordering! We&apos;re a bunch of food enthusiasts on a mission to
                redefine the way you experience ordering food. Our goal is to
                provide you with a smooth and enjoyable process to get your
                hands on your favorite meals.
              </Text>
              <Text align={"justify"}>
                Food Cart has been a game-changer in enhancing the offline food
                experience. We bring you the joy of savings from local stores,
                which are the heartbeat of our economy. By bridging the gap
                between retailers, big and small, and customers, Food Cart adds
                value to everyone in the hyperlocal retail ecosystem, allowing
                them to thrive in the rapidly growing digital landscape.
              </Text>
              <Text align={"justify"}>
                We&apos;re all about discovering new and exciting food options.
                Our platform boosts engagement and visibility for local stores,
                fueling demand and providing an omnichannel space to connect
                with the right local customers.
              </Text>
              <Text align={"justify"}>
                <Highlight
                  query={"Our journey"}
                  styles={{ px: "1", py: "1", bg: "orange.100" }}
                >
                  Our journey
                </Highlight>{" "}
                began with a realization that the traditional way of ordering
                food was outdated and inconvenient. We aimed to create a system
                that lets you order food effortlessly and swiftly, bypassing
                long lines and confusing menus. Thus, our self-ordering system
                was born.
              </Text>
              <Text align={"justify"}>
                <Highlight
                  query={"Our vision"}
                  styles={{ px: "1", py: "1", bg: "orange.100" }}
                >
                  Our vision
                </Highlight>{" "}
                is to revolutionize the food ordering experience, making it
                accessible to everyone, everywhere. With our self-ordering
                system, you can place your food order from your phone or tablet,
                and have it delivered straight to your doorstep.
              </Text>
              <Text align={"justify"}>
                At our core, we value innovation, convenience, and your
                satisfaction. We&apos;re always on the lookout for ways to
                enhance our system and make it even more user-friendly. We
                believe that ordering food should be a delightful and fun
                experience, and we&apos;re committed to turning that belief into
                reality for you.
              </Text>
              <Text align={"justify"}>
                Thank you for choosing our self-ordering system for food. We
                can&apos;t wait to serve you and make your food ordering
                experience exceptional!
              </Text>
            </Stack>
          </Container>
        </Box>
      </section>
      <section>
        <Flex align={"center"} justify={"center"} p={12}>
          <Stack
            boxShadow={"2xl"}
            bg={"#fff"}
            rounded={"xl"}
            p={10}
            spacing={8}
            align={"center"}
          >
            <Stack align={"center"} spacing={2}>
              <Text fontSize={"lg"} color={"gray.500"}>
                Subscribe to our newsletter & stay up to date!
              </Text>
            </Stack>
            <Stack
              spacing={4}
              direction={{ base: "column", md: "row" }}
              w={"full"}
            >
              <Input
                type={"text"}
                placeholder={"Email address"}
                color={"gray.800"}
                bg={"gray.100"}
                rounded={"full"}
                border={0}
                _focus={{
                  bg: "gray.200",
                  outline: "none",
                }}
              />
              <Button
                bg={"blue.400"}
                rounded={"full"}
                color={"white"}
                flex={"1 0 auto"}
                _hover={{ bg: "blue.500" }}
                _focus={{ bg: "blue.500" }}
              >
                Subscribe
              </Button>
            </Stack>
          </Stack>
        </Flex>
      </section>
    </>
  );
}
