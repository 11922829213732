import {
  Box,
  Button,
  Center,
  Container,
  Flex,
  Heading,
  Image,
  SimpleGrid,
  Stack,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import moment from "moment";
import {
  BiSolidBell,
  BiSolidEnvelope,
  BiSolidFoodMenu,
  BiSolidStore,
  BiSolidUser,
} from "react-icons/bi";
import { FaUsers } from "react-icons/fa";
import { PiNewspaperClippingFill, PiNotepadFill } from "react-icons/pi";

import { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import { homeTitleTag } from "../_data/headerTitleList";
import { HelmetTitleTag } from "../components/helmetTitleTag";
import { LoadingBlogList } from "../components/loadingCards/loadingBlogs";
import { getBlogs } from "../services/blogService";
import SlideHome from "./../_swiperSlides/slideHome";

interface BlogObj {
  current_page: number;
  data: any[];
  first_page_url: String;
  from: Number;
  last_page: Number;
  last_page_url: String;
  links: String;
  next_page_url: String;
  path: String;
  per_page: Number;
  prev_page_url: String;
  to: Number;
  total: Number;
}

export function HomePage() {
  const perPage = 6;
  const [blogs, setBlogs] = useState<BlogObj | null>();
  const [loadingBlogs, setLoadingBlogs] = useState<boolean>(true);
  const [pageFilter, setPageFilter] = useState<{
    per_page: number;
  }>({ per_page: perPage });
  const [loadBtn, setLoadBtn] = useState<{ N: boolean; P: boolean }>({
    N: false,
    P: false,
  });

  useEffect(() => {
    getBlogsByFilter();
  }, []);

  const getBlogsByFilter = () => {
    getBlogs(pageFilter)
      .then((response) => {
        if (response?.status === 200) {
          setBlogs(response?.data);
          setLoadingBlogs(false);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <HelmetTitleTag title={homeTitleTag.title} desc={homeTitleTag.desc} />

      <section>
        <Container maxW={"container.lg"}>
          <Stack
            align={"center"}
            spacing={{ base: 4, md: 5 }}
            py={10}
            direction={{ base: "column", md: "row" }}
          >
            <Stack flex={1} spacing={5}>
              <Heading
                lineHeight={1.1}
                fontWeight={600}
                fontSize={{ base: "3xl", sm: "4xl", lg: "6xl" }}
              >
                <Text
                  as={"span"}
                  position={"relative"}
                  _after={{
                    content: "''",
                    width: "full",
                    height: "30%",
                    position: "absolute",
                    bottom: 1,
                    left: 0,
                    bg: "red.400",
                    zIndex: -1,
                  }}
                >
                  Online Ordering System,
                </Text>
                <br />
                <Text as={"span"} color={"red.400"}>
                  use everywhere!
                </Text>
              </Heading>
              <Text color={"gray.500"} align={"justify"}>
                Welcome to Food Cart, where hassle-free and delightful food
                ordering is not just a service, but a culinary adventure. As a
                collective of food enthusiasts, we embarked on a mission to
                redefine the way you experience the joy of ordering food.
              </Text>
              <Stack
                spacing={{ base: 4, sm: 6 }}
                direction={{ base: "column", sm: "row" }}
              >
                <Button
                  rounded={"full"}
                  size={"lg"}
                  fontWeight={"normal"}
                  px={6}
                  colorScheme={"yellow"}
                  bg={"yellow.400"}
                  _hover={{ bg: "yellow.500" }}
                >
                  Self Order
                </Button>
                <Button
                  rounded={"full"}
                  size={"lg"}
                  fontWeight={"normal"}
                  px={6}
                >
                  How It Works
                </Button>
              </Stack>
            </Stack>
            <Stack flex={1} spacing={5}>
              <Box maxW="sm" mx={"auto"}>
                <SlideHome />
              </Box>
            </Stack>
          </Stack>
        </Container>
      </section>

      <section>
        <Box bg={"#fff"} py={20}>
          <Container maxW="container.lg">
            <Heading
              textAlign={"center"}
              color={"gray.700"}
              fontSize={"4xl"}
              fontFamily={"body"}
              p={4}
            >
              Our Features
            </Heading>
            <Stack
              direction={{ base: "column", md: "row" }}
              spacing={15}
              justify={"center"}
            >
              <Box minW={"xs"} p="10" fontSize={"20px"}>
                <Stack direction={"column"} spacing={50}>
                  <Flex align={"center"}>
                    <Box pr={4}>
                      <BiSolidStore color={"#1E88E5"} size={30} />
                    </Box>
                    <Text fontWeight={"500"}>Shop Management</Text>
                  </Flex>
                  <Flex align={"center"}>
                    <Box pr={4}>
                      <BiSolidFoodMenu color={"#1E88E5"} size={30} />
                    </Box>
                    <Text fontWeight={"500"}>Menu</Text>
                  </Flex>
                  <Flex align={"center"}>
                    <Box pr={4}>
                      <BiSolidUser color={"#1E88E5"} size={30} />
                    </Box>
                    <Text fontWeight={"500"}>Users</Text>
                  </Flex>
                  <Flex align={"center"}>
                    <Box pr={4}>
                      <BiSolidBell color={"#1E88E5"} size={30} />
                    </Box>
                    <Text fontWeight={"500"}>Notification</Text>
                  </Flex>
                </Stack>
              </Box>
              <Box minW={"xs"} p="10" fontSize={"20px"}>
                <Stack direction={"column"} spacing={50}>
                  <Flex align={"center"}>
                    <Box pr={4}>
                      <PiNotepadFill color={"#1E88E5"} size={30} />
                    </Box>
                    <Text fontWeight={"500"}>Orders</Text>
                  </Flex>
                  <Flex align={"center"}>
                    <Box pr={4}>
                      <PiNewspaperClippingFill color={"#1E88E5"} size={30} />
                    </Box>
                    <Text fontWeight={"500"}>Bills</Text>
                  </Flex>
                  <Flex align={"center"}>
                    <Box pr={4}>
                      <FaUsers color={"#1E88E5"} size={30} />
                    </Box>
                    <Text fontWeight={"500"}>Staff</Text>
                  </Flex>
                  <Flex align={"center"}>
                    <Box pr={4}>
                      <BiSolidEnvelope color={"#1E88E5"} size={30} />
                    </Box>
                    <Text fontWeight={"500"}>Emails</Text>
                  </Flex>
                </Stack>
              </Box>
            </Stack>
          </Container>
        </Box>
      </section>

      <section>
        <Box py={20}>
          <Container maxW="container.lg">
            <Heading
              textAlign={"center"}
              fontSize={"4xl"}
              fontFamily={"body"}
              p={4}
            >
              Blogs
            </Heading>
            <Center py={6}>
              <SimpleGrid columns={[1, 2, 3]} spacing={6} py={6}>
                {loadingBlogs === true ? (
                  [...Array(6)].map((i, index) => (
                    <Box key={`loadingBlogs${index}`}>
                      <LoadingBlogList />
                    </Box>
                  ))
                ) : (
                  <>
                    {blogs && blogs!.data.length > 0 ? (
                      <>
                        {blogs!.data.map((blog) => (
                          <Link to={`/blog/${blog.slug}`} key={blog.id}>
                            <Box
                              maxW={"300px"}
                              w={"full"}
                              boxShadow={"2xl"}
                              rounded={"md"}
                              p={6}
                              overflow={"hidden"}
                              _hover={{ textDecoration: "none" }}
                              bg={"#fff"}
                            >
                              <Box
                                h={"200px"}
                                bg={"gray.100"}
                                mt={-6}
                                mx={-6}
                                mb={6}
                                pos={"relative"}
                              >
                                <Image
                                  src={blog.blog_image}
                                  alt={blog.title}
                                  h={"200px"}
                                />
                              </Box>
                              <Stack>
                                <Text
                                  color={"green.500"}
                                  textTransform={"uppercase"}
                                  fontWeight={800}
                                  fontSize={"sm"}
                                  letterSpacing={1.1}
                                >
                                  {moment(blog.created_at).format(
                                    "MMM DD, YYYY"
                                  )}
                                </Text>
                                <Tooltip
                                  label={blog.title}
                                  fontSize="md"
                                  hasArrow
                                >
                                  <Heading
                                    fontSize={"2xl"}
                                    fontFamily={"body"}
                                    noOfLines={1}
                                  >
                                    {blog.title}
                                  </Heading>
                                </Tooltip>
                              </Stack>
                              <Box mt={6}>
                                <Text>Read More</Text>
                              </Box>
                            </Box>
                          </Link>
                        ))}
                      </>
                    ) : (
                      <Box py={20}>
                        <Heading textAlign={"center"}>
                          No Blogs to list!
                        </Heading>
                      </Box>
                    )}
                  </>
                )}
              </SimpleGrid>
            </Center>
            <Center p={4}>
              <Link to="/blog">
                <Box textAlign={"center"} p={4} border={"1px solid #000"}>
                  More Blog Releases
                </Box>
              </Link>
            </Center>
          </Container>
        </Box>
      </section>
    </>
  );
}
