import {
  Avatar,
  Box,
  Center,
  Container,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Image,
  Spacer,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { authLink, menuLeftSlide, menuTop } from "./../_data/menuList";
import logo from "./../assets/logo.png";
import { MyLeftLink } from "./../components/MyButtons";

import moment from "moment";
import { BiMenu } from "react-icons/bi";
import { Link } from "react-router-dom";

export default function Header() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <header>
      <Box bg={"#fff"} boxShadow={"md"}>
        <Container maxW="container.lg">
          <Flex justify={"center"}>
            <Center py="4">
              <Link to="/">
                <Image src={logo} alt="Logo" w={"40px"} />
              </Link>
            </Center>
            <Spacer />
            <Center py="4">
              <Box display={{ base: "none", md: "block" }}>
                <Stack direction={"row"} spacing={4}>
                  {menuTop.map((menu) => (
                    <Link to={menu.url} key={menu.id}>
                      <Text p={2}>{menu.name}</Text>
                    </Link>
                  ))}
                  <Link to={authLink?.login?.url}>
                    <Box
                      border={"1px solid green"}
                      color={"green"}
                      px={4}
                      py={2}
                      rounded={"lg"}
                      _hover={{ bg: "green.200" }}
                    >
                      {authLink?.login?.name}
                    </Box>
                  </Link>
                  <Link to={authLink?.register?.url}>
                    <Box
                      bg={"green"}
                      color={"#ffff"}
                      px={4}
                      py={2}
                      rounded={"lg"}
                      _hover={{ bg: "green.600" }}
                    >
                      {authLink?.register?.name}
                    </Box>
                  </Link>
                </Stack>
              </Box>
              <Box display={{ base: "block", md: "none" }}>
                <BiMenu size={"35px"} onClick={onOpen} cursor={"pointer"} />
              </Box>
              <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
                <DrawerOverlay />
                <DrawerContent>
                  <DrawerCloseButton />
                  <DrawerHeader borderBottom={"1px"} borderColor={"gray.400"}>
                    <Center>
                      <Avatar size="lg" name="" />
                    </Center>
                    <Text textAlign={"center"} noOfLines={2}>
                      Menu
                    </Text>
                  </DrawerHeader>

                  <DrawerBody px={0}>
                    {menuLeftSlide.map((menuLeft) => (
                      <MyLeftLink
                        key={menuLeft.id}
                        name={menuLeft.name}
                        hrefUrl={menuLeft.url}
                        linkIcon={menuLeft.icon}
                        clkEvent={onClose}
                      />
                    ))}
                  </DrawerBody>

                  <DrawerFooter
                    borderTop={"1px"}
                    borderColor={"gray.400"}
                    justifyContent={"center"}
                    p={2}
                  >
                    <Text>
                      &copy; {moment().format("YYYY")}. All rights reserved
                    </Text>
                  </DrawerFooter>
                </DrawerContent>
              </Drawer>
            </Center>
          </Flex>
        </Container>
      </Box>
    </header>
  );
}
