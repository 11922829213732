import {
  AspectRatio,
  Box,
  Center,
  Container,
  Flex,
  Heading,
  Img,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";
import moment from "moment";

import { useEffect, useState } from "react";
import { BiSolidLeftArrowCircle } from "react-icons/bi";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { blogViewTitleTag } from "../_data/headerTitleList";
import { HelmetTitleTag } from "../components/helmetTitleTag";
import { LoadingBlogView } from "./../components/loadingCards/loadingBlogs";
import { getBlogView } from "./../services/blogService";

interface _BlogInfo {
  id: number;
  title: string;
  seo_title: any;
  excerpt: string;
  body: string;
  image: string;
  slug: string;
  meta_description?: string;
  meta_keywords?: string;
  status: string;
  featured?: number;
  created_at: string;
  blog_image?: string;
}

export function BlogViewPage() {
  const urlSlug = useParams()?.blogSlug;
  const [loading, setLoading] = useState<boolean>(true);
  const [blogData, setBlogData] = useState<_BlogInfo | null>();
  const [pageFilter, setPageFilter] = useState<{
    blog_slug: string;
  }>({ blog_slug: urlSlug!.toString() });

  const navigate = useNavigate();
  const location = useLocation();
  const redirectPath = location.state?.path || "/blog";

  useEffect(() => {
    getBlogInfo();
  }, []);

  const getBlogInfo = () => {
    getBlogView(pageFilter)
      .then((response) => {
        if (response?.status === 200) {
          setBlogData(response?.data);
          setLoading(false);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 404) {
          navigate(redirectPath, { replace: true }); // REPLACE: TRUE - ARGUMENT IS FOR TO STOPE REDIRECTING TO LOGIN PAGE
        }
        // console.log(error);
      });
  };

  return (
    <>
      {loading === true ? (
        <>
          <LoadingBlogView />
          <HelmetTitleTag
            title={blogViewTitleTag.title}
            desc={blogViewTitleTag.desc}
          />
        </>
      ) : (
        <>
          {blogData && blogData!.id !== null ? (
            <>
              <HelmetTitleTag
                title={blogData.title}
                desc={blogData.seo_title}
              />
              <section>
                <AspectRatio ratio={21 / 9}>
                  <Img src={blogData.blog_image} alt={blogData.title} />
                </AspectRatio>
              </section>
              <section>
                <Box py={8} textAlign={"left"}>
                  <Container maxW="container.lg">
                    <SimpleGrid spacing={6} py={6} textAlign={"justify"}>
                      <Heading
                        fontSize={"2xl"}
                        fontFamily={"body"}
                        textAlign={"left"}
                      >
                        {blogData.title}
                      </Heading>
                      <Text>
                        {moment(blogData.created_at).format("MMM D, YYYY")}
                      </Text>
                      <Text color={"gray.500"}>{blogData.excerpt}</Text>
                      <Box
                        dangerouslySetInnerHTML={{ __html: blogData.body }}
                      ></Box>
                    </SimpleGrid>

                    <Center p={4}>
                      <Link to="/blog">
                        <Box
                          textAlign={"center"}
                          p={4}
                          border={"1px solid #000"}
                          _hover={{ textDecoration: "none" }}
                        >
                          <Flex align={"center"} gap={2}>
                            <BiSolidLeftArrowCircle /> Back to blog list
                          </Flex>
                        </Box>
                      </Link>
                    </Center>
                  </Container>
                </Box>
              </section>
            </>
          ) : null}
        </>
      )}
    </>
  );
}
