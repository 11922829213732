import {
  Avatar,
  Badge,
  Box,
  Center,
  Container,
  Flex,
  Heading,
  IconButton,
  Input,
  SimpleGrid,
  Stack,
  Text,
} from "@chakra-ui/react";

import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { BiSearch, BiSolidStoreAlt, BiSolidTime } from "react-icons/bi";
import { BsFillCupHotFill } from "react-icons/bs";
import {
  GiChickenOven,
  GiForkKnifeSpoon,
  GiSlicedBread,
  GiWaterBottle,
} from "react-icons/gi";
import { IoMdPin } from "react-icons/io";
import { LuCupSoda, LuSalad, LuSoup, LuVegan } from "react-icons/lu";
import { MdFastfood } from "react-icons/md";
import { PiBowlFoodFill } from "react-icons/pi";
import { Link, useParams } from "react-router-dom";
import { shopViewTitleTag } from "../_data/headerTitleList";
import { HelmetTitleTag } from "../components/helmetTitleTag";
import {
  LoadingCardShopView,
  LoadingCardShopViewItems,
} from "../components/loadingCards/loadingCardShopView";
import { useQuery } from "./../_lib/queryParams";
import { getStoreItems } from "./../services/shopService";

const slideItems = [
  {
    name: "All",
    icon: <BiSolidStoreAlt />,
  },
  {
    name: "Drinks",
    icon: <GiWaterBottle />,
  },
  {
    name: "Hot",
    icon: <BsFillCupHotFill />,
  },
  {
    name: "Cool",
    icon: <LuCupSoda />,
  },
  {
    name: "Fast Food",
    icon: <MdFastfood />,
  },
  {
    name: "Breakfast",
    icon: <GiSlicedBread />,
  },
  {
    name: "Lunch",
    icon: <GiForkKnifeSpoon />,
  },
  {
    name: "Dinner",
    icon: <PiBowlFoodFill />,
  },
  {
    name: "Veg",
    icon: <LuVegan />,
  },
  {
    name: "Non Veg",
    icon: <GiChickenOven />,
  },
  {
    name: "Salad",
    icon: <LuSalad />,
  },
  {
    name: "Soup",
    icon: <LuSoup />,
  },
];
interface StoreObj {
  id: number;
  store_owner_id: number;
  store_title: string;
  store_sub_title: string;
  store_address: string;
  store_city: string;
  store_state: string;
  store_postcode: string;
  store_hours: string;
  store_phone: string;
  store_logo: string;
  store_logo_url?: string;
  store_status: string;
  created_at: string;
  updated_at: string;
  items: Array<_Item>;
}
interface _Item {
  id: number;
  store_id: number;
  item_title: string;
  item_cost: string;
  item_category: string;
  item_sub_categories: string;
  item_image: string;
  item_status: string;
  created_at: string;
  updated_at: string;
  item_image_url: string;
}

export function ShopViewPage() {
  const shop_id = useParams()?.shopId;
  let query = useQuery();

  const [loading, setLoading] = useState<boolean>(true);
  const [loadingItems, setLoadingItems] = useState<boolean>(true);
  const [storeData, setStoreData] = useState<StoreObj | null>();
  const [currentItems, setCurrentItems] = useState<Array<_Item>>();
  const [itemCategories, setItemCategories] = useState<Array<String>>();
  const [showMore, setShowMore] = useState<Boolean>(false);
  const [pageFilter, setPageFilter] = useState<{
    store_slug: string;
  }>({ store_slug: shop_id!.toString() });

  useEffect(() => {
    getStoreWithItems();
  }, []);

  const getStoreWithItems = () => {
    getStoreItems(pageFilter).then((response) => {
      if (response?.status === 200) {
        setStoreData(response?.data);
        setCurrentItems(response?.data?.items);
        let categorySet: Set<String> = new Set();
        categorySet.add("All");
        response?.data?.items
          .map((item: any) => item.item_category)
          .forEach((element: string) => {
            categorySet.add(element);
          });
        setItemCategories(Array.from(categorySet));
        setLoading(false);
        setLoadingItems(false);
      }
    });
  };

  const { register, handleSubmit } = useForm();

  const onSubmit = async (searchFilter: any) => {
    const searchItems = storeData!.items.filter((shopItem) => {
      if (
        shopItem.item_title
          ?.toLowerCase()
          .includes(searchFilter.search_for?.toLowerCase()) ||
        shopItem.item_category
          ?.toLowerCase()
          .includes(searchFilter.search_for?.toLowerCase()) ||
        shopItem.item_sub_categories
          ?.toLowerCase()
          .includes(searchFilter.search_for?.toLowerCase())
      ) {
        return shopItem;
      }
    });
    setCurrentItems(searchItems);
  };

  return (
    <>
      <section>
        {loading === true ? (
          <>
            <HelmetTitleTag
              title={shopViewTitleTag.title}
              desc={shopViewTitleTag.desc}
            />
            <LoadingCardShopView />
          </>
        ) : (
          <>
            {storeData && Object.keys(storeData).length > 0 ? (
              <>
                <HelmetTitleTag
                  title={storeData?.store_title}
                  desc={storeData?.store_title}
                />
                <Box bg={"#fff"} mt={1}>
                  <Container maxW="container.lg" pt={4}>
                    <Flex
                      direction={{ base: "column", lg: "row" }}
                      justify={"space-between"}
                    >
                      <Flex
                        flex="1"
                        gap="4"
                        alignItems="center"
                        flexWrap="wrap"
                      >
                        <Avatar
                          src={storeData?.store_logo_url}
                          size="lg"
                          name={storeData?.store_title}
                          rounded={"md"}
                          borderRadius={"md"}
                        />
                        <Box>
                          <Heading size="md">{storeData?.store_title}</Heading>
                          <Text color={"gray.500"} fontSize={"sm"}>
                            {storeData?.store_sub_title}
                          </Text>
                          <Text
                            color={"gray.500"}
                            fontSize={"sm"}
                            onClick={() => setShowMore(!showMore)}
                            cursor={"pointer"}
                            _hover={{ textDecoration: "underline" }}
                          >
                            more
                          </Text>
                        </Box>
                      </Flex>
                      <Flex
                        direction={"column"}
                        pt={2}
                        display={!showMore ? "none" : "auto"}
                      >
                        <Flex align={"center"}>
                          <Box pr={1}>
                            <BiSolidTime size={"14"} />
                          </Box>
                          <Text
                            noOfLines={1}
                            color={"gray.500"}
                            fontSize={"sm"}
                          >
                            {storeData?.store_hours}
                          </Text>
                        </Flex>
                        <Flex align={"center"}>
                          <Box pr={1}>
                            <IoMdPin size={"14"} />
                          </Box>
                          <Text
                            noOfLines={1}
                            color={"gray.500"}
                            fontSize={"sm"}
                          >
                            {storeData?.store_city}
                          </Text>
                        </Flex>
                      </Flex>
                    </Flex>
                  </Container>
                </Box>
              </>
            ) : null}
          </>
        )}
      </section>
      {storeData && Object.keys(storeData).length > 0 ? (
        <section>
          <Box bg={"#fff"} justifyContent={"center"} boxShadow={"md"}>
            <Container maxW="container.lg" p={4}>
              <form onSubmit={handleSubmit(onSubmit)} id="fromOne">
                <Stack direction={"row"} spacing={0}>
                  <Input
                    {...register("search_for")}
                    size={"lg"}
                    htmlSize={100}
                    placeholder={"Search"}
                    bg={"blackAlpha.100"}
                    roundedLeft={"full"}
                    border={0}
                    _focus={{
                      bg: "whiteAlpha.300",
                    }}
                  />
                  <IconButton
                    as="button"
                    size={"lg"}
                    bg={"green.400"}
                    color={"white"}
                    roundedRight={"full"}
                    _hover={{
                      bg: "green.600",
                    }}
                    aria-label="Search"
                    icon={<BiSearch />}
                    type="submit"
                    form="fromOne"
                  />
                </Stack>
              </form>
            </Container>

            <Container maxW="container.lg" pb={4}>
              <Flex
                gap={2}
                overflow={"auto"}
                sx={{
                  "::-webkit-scrollbar": {
                    display: "none",
                  },
                }}
              >
                {itemCategories && itemCategories?.length > 0
                  ? slideItems
                      .filter((itemC) => itemCategories?.includes(itemC.name))
                      .map((slideItem) => (
                        <Link
                          to={`?menu=${
                            slideItem.name === "All" ? "" : slideItem.name
                          }`}
                          key={slideItem.name}
                          onClick={() =>
                            onSubmit({
                              search_for:
                                slideItem.name === "All" ? "" : slideItem.name,
                            })
                          }
                        >
                          <Badge
                            fontSize="xs"
                            fontWeight={"none"}
                            variant={
                              !query.get("menu") && slideItem.name === "All"
                                ? "solid"
                                : query.get("menu") === slideItem.name
                                ? "solid"
                                : "outline"
                            }
                            colorScheme="orange"
                            rounded={"full"}
                            textTransform="none"
                            py={1}
                            px={2}
                            _hover={{ textDecoration: "none" }}
                          >
                            <Flex align={"center"}>
                              {slideItem.icon}
                              <Text pl={1}>{slideItem.name}</Text>
                            </Flex>
                          </Badge>
                        </Link>
                      ))
                  : null}
              </Flex>
            </Container>
          </Box>
        </section>
      ) : null}
      <section>
        <Box py={6}>
          <Container maxW="container.lg">
            <SimpleGrid columns={[1, null, 2]} spacing={4}>
              {loadingItems === true ? (
                [...Array(6)].map((i, index) => (
                  <Box key={`loadingItems${index}`}>
                    <LoadingCardShopViewItems />
                  </Box>
                ))
              ) : (
                <>
                  {storeData &&
                  Object.keys(storeData).length > 0 &&
                  storeData!.id !== null &&
                  currentItems!.length > 0 ? (
                    currentItems?.map((item) => (
                      <Flex
                        bg={"white"}
                        boxShadow={"md"}
                        rounded={"md"}
                        key={item.id}
                      >
                        <Center p={2}>
                          <Avatar
                            src={item.item_image_url}
                            name={item.item_title}
                            size="lg"
                            rounded={"md"}
                            borderRadius={"md"}
                          />
                        </Center>
                        <Flex
                          direction={"column"}
                          justify={"space-evenly"}
                          p={2}
                        >
                          <Text fontWeight={"600"} noOfLines={1}>
                            {item.item_title}
                          </Text>
                          <Text
                            color={"gray.500"}
                            fontSize={"sm"}
                            noOfLines={1}
                          >
                            {item.item_category}
                          </Text>
                          <Text color={"red.500"} fontWeight={"600"}>
                            RM {item.item_cost}
                          </Text>
                        </Flex>
                      </Flex>
                    ))
                  ) : (
                    <Box py={40}>
                      <Heading as={"h5"} textAlign={"center"}>
                        No store/items to list!
                      </Heading>
                    </Box>
                  )}
                </>
              )}
            </SimpleGrid>
          </Container>
        </Box>
      </section>
    </>
  );
}
