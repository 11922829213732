export const homeTitleTag = {
  title: "Welcome to Home page!",
  desc: "",
};

export const aboutTitleTag = {
  title: "Welcome to About page!",
  desc: "",
};

export const blogListTitleTag = {
  title: "Welcome to Blog page!",
  desc: "",
};

export const blogViewTitleTag = {
  title: "Welcome to Blog view page!",
  desc: "",
};

export const shopListTitleTag = {
  title: "Welcome to Shop List page!",
  desc: "",
};

export const shopViewTitleTag = {
  title: "Welcome to Shop view page!",
  desc: "",
};

export const loginTitleTag = {
  title: "Welcome to Login page!",
  desc: "",
};

export const registerTitleTag = {
  title: "Welcome to Register page!",
  desc: "",
};

export const forgotPasswordTitleTag = {
  title: "Welcome to Forgot password page!",
  desc: "",
};
