import { Avatar, Box, Container, Flex, Skeleton } from "@chakra-ui/react";

export function LoadingCardShopView() {
  return (
    <Box bg={"#fff"} mt={1}>
      <Container maxW="container.lg" py={4}>
        <Flex
          direction={{ base: "column", lg: "row" }}
          justify={"space-between"}
        >
          <Flex flex="1" gap="4" alignItems="center" flexWrap="wrap">
            <Skeleton>
              <Avatar
                size="lg"
                name="Loading..."
                rounded={"md"}
                borderRadius={"md"}
              />
            </Skeleton>
            <Flex direction={"column"} gap={2}>
              <Skeleton height="18px">
                <Box w={"230px"}></Box>
              </Skeleton>
              <Skeleton height="14px">
                <Box w={"230px"}></Box>
              </Skeleton>
            </Flex>
          </Flex>
        </Flex>
      </Container>
    </Box>
  );
}

export function LoadingCardShopViewItems() {
  return (
    <Flex bg={"white"} boxShadow={"md"} rounded={"md"}>
      <Box p={2}>
        <Skeleton>
          <Avatar
            size="lg"
            name="Loading..."
            rounded={"md"}
            borderRadius={"md"}
          />
        </Skeleton>
      </Box>
      <Flex direction={"column"} justify={"space-evenly"} p={2}>
        <Skeleton height="18px">
          <Box w={"230px"}></Box>
        </Skeleton>
        <Skeleton height="14px">
          <Box w={"230px"}></Box>
        </Skeleton>
      </Flex>
    </Flex>
  );
}
