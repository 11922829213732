import {
  Avatar,
  Box,
  Button,
  Container,
  Flex,
  Heading,
  IconButton,
  Input,
  SimpleGrid,
  Stack,
  Text,
} from "@chakra-ui/react";

import { LoadingCardShopList } from "./../components/loadingCards/loadingCardShopList";

import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  BiSearch,
  BiSolidLeftArrowCircle,
  BiSolidRightArrowCircle,
} from "react-icons/bi";
import { Link } from "react-router-dom";
import { shopListTitleTag } from "../_data/headerTitleList";
import { HelmetTitleTag } from "../components/helmetTitleTag";
import { getStores } from "./../services/shopService";

interface StoreObj {
  current_page: number;
  data: any[];
  first_page_url: String;
  from: Number;
  last_page: Number;
  last_page_url: String;
  links: String;
  next_page_url: String;
  path: String;
  per_page: Number;
  prev_page_url: String;
  to: Number;
  total: Number;
}

export function ShopListPage() {
  const perPage = 20;
  const [stores, setStores] = useState<StoreObj | null>();
  const [loading, setLoading] = useState<boolean>(true);
  const [pageFilter, setPageFilter] = useState<{
    per_page: number;
    page: number;
    search_for: string | null;
  }>({ per_page: perPage, page: 1, search_for: null });
  const [loadBtn, setLoadBtn] = useState<{ N: boolean; P: boolean }>({
    N: false,
    P: false,
  });

  useEffect(() => {
    getStoresByFilter();
  }, []);

  const storePaginate = (clickType: string) => {
    if (clickType === "N") {
      pageFilter.page++;
      setLoadBtn({ N: true, P: false });
    }
    if (clickType === "P") {
      pageFilter.page--;
      setLoadBtn({ N: false, P: true });
    }
    getStoresByFilter();
  };

  const getStoresByFilter = () => {
    getStores(pageFilter)
      .then((response) => {
        if (response?.status === 200) {
          setStores(response?.data);
          setLoading(false);
          setLoadBtn({ N: false, P: false });
        }
      })
      .catch((err) => console.log(err));
  };

  const {
    register,
    handleSubmit,
  } = useForm();

  const onSubmit = async (searchFilter: any) => {
    pageFilter.search_for = searchFilter.search_for;
    pageFilter.page = 1;
    getStoresByFilter();
  };
  return (
    <>
      <HelmetTitleTag
        title={shopListTitleTag.title}
        desc={shopListTitleTag.desc}
      />
      <section>
        <Box bg={"#fff"} justifyContent={"center"} mt={1} boxShadow={"md"}>
          <Container maxW="container.lg" p={4}>
            <form onSubmit={handleSubmit(onSubmit)} id="fromOne">
              <Stack direction={"row"} spacing={0}>
                <Input
                  {...register("search_for")}
                  size={"lg"}
                  htmlSize={100}
                  placeholder={"Search"}
                  bg={"blackAlpha.100"}
                  roundedLeft={"full"}
                  border={0}
                  _focus={{
                    bg: "whiteAlpha.300",
                  }}
                />
                <IconButton
                  as="button"
                  size={"lg"}
                  bg={"green.400"}
                  color={"white"}
                  roundedRight={"full"}
                  _hover={{
                    bg: "green.600",
                  }}
                  aria-label="Search"
                  icon={<BiSearch />}
                  type="submit"
                  form="fromOne"
                />
              </Stack>

              {/* <p className="text-danger">{errors?.search_for?.message}</p> */}
            </form>
          </Container>
        </Box>
      </section>
      <section>
        <Box py={8}>
          <Container maxW="container.lg">
            <SimpleGrid columns={[1, 1, 2]} spacing={4}>
              {loading === true ? (
                [...Array(6)].map((i, index) => (
                  <Box key={`loading${index}`}>
                    <LoadingCardShopList />
                  </Box>
                ))
              ) : (
                <>
                  {stores && stores!.data.length > 0 ? (
                    <>
                      {stores!.data.map((store) => (
                        <Link to={`/shop/${store.store_slug}`} key={store.id}>
                          <Box
                            w={"full"}
                            boxShadow={"md"}
                            rounded={"md"}
                            p={2}
                            bg={"#fff"}
                            _hover={{ textDecoration: "none" }}
                          >
                            <Flex direction="row" alignItems={"center"}>
                              <Box pr={4}>
                                <Avatar
                                  src={store.store_logo_url}
                                  size="lg"
                                  name={store.store_title}
                                  rounded={"sm"}
                                  borderRadius={"sm"}
                                />
                              </Box>
                              <Box>
                                <Flex direction={"column"}>
                                  <Text fontSize={"lg"} fontWeight={"bold"}>
                                    {store.store_title}
                                  </Text>
                                  <Text noOfLines={1} color={"gray"}>
                                    {store.store_city}
                                  </Text>
                                </Flex>
                              </Box>
                            </Flex>
                          </Box>
                        </Link>
                      ))}
                    </>
                  ) : (
                    <Box py={20}>
                      <Heading textAlign={"center"}>No shops to list!</Heading>
                    </Box>
                  )}
                </>
              )}
            </SimpleGrid>
          </Container>
          <Container maxW="container.lg" py={8}>
            {stores && stores!.current_page > 0 ? (
              <Flex justify={"space-between"}>
                <Button
                  leftIcon={<BiSolidLeftArrowCircle />}
                  colorScheme="teal"
                  variant="outline"
                  boxShadow={"md"}
                  isDisabled={stores!.prev_page_url === null ? true : false}
                  onClick={() => storePaginate("P")}
                  isLoading={loadBtn.P}
                  loadingText={"Loading..."}
                  spinnerPlacement={"start"}
                >
                  Prev
                </Button>

                <Button
                  rightIcon={<BiSolidRightArrowCircle />}
                  colorScheme="teal"
                  variant="outline"
                  boxShadow={"md"}
                  isDisabled={stores!.next_page_url === null ? true : false}
                  onClick={() => storePaginate("N")}
                  isLoading={loadBtn.N}
                  loadingText={"Loading..."}
                  spinnerPlacement={"end"}
                >
                  Next
                </Button>
              </Flex>
            ) : (
              <></>
            )}
          </Container>
        </Box>
      </section>
    </>
  );
}
