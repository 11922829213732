import {
  Box,
  Center,
  Container,
  Heading,
  Image,
  SimpleGrid,
  Stack,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import moment from "moment";

import { Link } from "react-router-dom";
import { LoadingBlogList } from "./../components/loadingCards/loadingBlogs";

import { useEffect, useState } from "react";
import { blogListTitleTag } from "../_data/headerTitleList";
import { HelmetTitleTag } from "../components/helmetTitleTag";
import { getBlogs } from "./../services/blogService";

interface BlogObj {
  current_page: number;
  data: any[];
  first_page_url: String;
  from: Number;
  last_page: Number;
  last_page_url: String;
  links: String;
  next_page_url: String;
  path: String;
  per_page: Number;
  prev_page_url: String;
  to: Number;
  total: Number;
}

export function BlogListPage() {
  const perPage = 20;
  const [blogs, setBlogs] = useState<BlogObj | null>();
  const [loading, setLoading] = useState<boolean>(true);
  const [pageFilter, setPageFilter] = useState<{
    per_page: number;
    page: number;
    search_for: string | null;
  }>({ per_page: perPage, page: 1, search_for: null });
  const [loadBtn, setLoadBtn] = useState<{ N: boolean; P: boolean }>({
    N: false,
    P: false,
  });

  useEffect(() => {
    getBlogsByFilter();
  }, []);

  const blogPaginate = (clickType: string) => {
    if (clickType === "N") {
      pageFilter.page++;
      setLoadBtn({ N: true, P: false });
    }
    if (clickType === "P") {
      pageFilter.page--;
      setLoadBtn({ N: false, P: true });
    }
    getBlogsByFilter();
  };

  const getBlogsByFilter = () => {
    getBlogs(pageFilter)
      .then((response) => {
        if (response?.status === 200) {
          setBlogs(response?.data);
          setLoading(false);
          setLoadBtn({ N: false, P: false });
        }
      })
      .catch((err) => console.log(err));
  };
  return (
    <>
      <HelmetTitleTag
        title={blogListTitleTag.title}
        desc={blogListTitleTag.desc}
      />
      <section>
        <Box py={8}>
          <Container maxW="container.lg">
            <Heading
              textAlign={"center"}
              fontSize={"4xl"}
              fontFamily={"body"}
              p={4}
            >
              Blog
            </Heading>
            <Center py={6}>
              <SimpleGrid columns={[1, 2, 3]} spacing={6} py={6}>
                {loading === true ? (
                  [...Array(6)].map((i, index) => (
                    <Box key={`loading${index}`}>
                      <LoadingBlogList />
                    </Box>
                  ))
                ) : (
                  <>
                    {blogs && blogs!.data.length > 0 ? (
                      <>
                        {blogs!.data.map((blog) => (
                          <Link to={`/blog/${blog.slug}`} key={blog.id}>
                            <Box
                              maxW={"300px"}
                              w={"full"}
                              boxShadow={"2xl"}
                              rounded={"md"}
                              p={6}
                              overflow={"hidden"}
                              _hover={{ textDecoration: "none" }}
                              bg={"#fff"}
                            >
                              <Box
                                h={"200px"}
                                bg={"gray.100"}
                                mt={-6}
                                mx={-6}
                                mb={6}
                                pos={"relative"}
                              >
                                <Image
                                  src={blog.blog_image}
                                  alt={blog.title}
                                  h={"200px"}
                                />
                              </Box>
                              <Stack>
                                <Text
                                  color={"green.500"}
                                  textTransform={"uppercase"}
                                  fontWeight={800}
                                  fontSize={"sm"}
                                  letterSpacing={1.1}
                                >
                                  {moment(blog.created_at).format(
                                    "MMM DD, YYYY"
                                  )}
                                </Text>
                                <Tooltip
                                  label={blog.title}
                                  fontSize="md"
                                  hasArrow
                                >
                                  <Heading
                                    fontSize={"2xl"}
                                    fontFamily={"body"}
                                    noOfLines={1}
                                  >
                                    {blog.title}
                                  </Heading>
                                </Tooltip>
                              </Stack>
                              <Box mt={6}>
                                <Text>Read More</Text>
                              </Box>
                            </Box>
                          </Link>
                        ))}
                      </>
                    ) : (
                      <Box py={20}>
                        <Heading textAlign={"center"}>
                          No Blogs to list!
                        </Heading>
                      </Box>
                    )}
                  </>
                )}
              </SimpleGrid>
            </Center>
          </Container>
        </Box>
      </section>
    </>
  );
}
