import {
  BiNews,
  BiSolidHome,
  BiSolidInfoCircle,
  BiSolidLogIn,
  BiSolidStore,
  BiSolidUserPlus,
} from "react-icons/bi";
import { FaFacebook, FaInstagram, FaYoutube } from "react-icons/fa";

export const menuTop = [
  {
    id: "1",
    name: "Home",
    url: "/",
  },
  {
    id: "2",
    name: "About",
    url: "/about",
  },
  {
    id: "3",
    name: "Blog",
    url: "/blog",
  },
  {
    id: "4",
    name: "Shop",
    url: "/shop",
  },
];

export const menuBottom = [
  {
    id: "1",
    name: "Home",
    url: "/",
  },
  {
    id: "2",
    name: "About",
    url: "/about",
  },
  {
    id: "3",
    name: "Blog",
    url: "/blog",
  },
  {
    id: "4",
    name: "Shop",
    url: "/shop",
  },
  {
    id: "5",
    name: "T&c",
    url: "/terms-and-conditions",
  },
];

export const socialMediaBottom = [
  {
    id: "1",
    name: "Facebook",
    icon: <FaFacebook />,
    color: "#4267B2",
    url: "https://www.facebook.com/foodcrt",
  },
  {
    id: "2",
    name: "Youtube",
    icon: <FaYoutube />,
    color: "#FF0000",
    url: "/youtube",
  },
  {
    id: "3",
    name: "Instagram",
    icon: <FaInstagram />,
    color: "#E1306C",
    url: "https://www.instagram.com/foodcart.biz",
  },
];

export const authLink = {
  login: {
    id: "1",
    name: "Log in",
    url: "/login",
  },
  register: {
    id: "2",
    name: "Sign up",
    url: "/register",
  },
};

export const menuLeftSlide = [
  {
    id: "1",
    name: "Home",
    url: "/",
    icon: <BiSolidHome />,
  },
  {
    id: "2",
    name: "About",
    url: "/about",
    icon: <BiSolidInfoCircle />,
  },
  {
    id: "3",
    name: "Blog",
    url: "/blog",
    icon: <BiNews />,
  },
  {
    id: "4",
    name: "Shop",
    url: "/shop",
    icon: <BiSolidStore />,
  },
  {
    id: "5",
    name: "Login",
    url: "/login",
    icon: <BiSolidLogIn />,
  },
  {
    id: "6",
    name: "Sign up",
    url: "/register",
    icon: <BiSolidUserPlus />,
  },
];
