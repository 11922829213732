"use client";

import { Link } from "react-router-dom";
import { menuBottom, socialMediaBottom } from "./../_data/menuList";
import logo from "./../assets/logo.png";
import { Box, Container, Image, Stack, Text } from "@chakra-ui/react";
import moment from "moment";

export default function Footer() {
  return (
    <footer>
      <Box bg={"gray.50"} color={"gray.700"}>
        <Container
          as={Stack}
          maxW={"6xl"}
          py={4}
          spacing={4}
          justify={"center"}
          align={"center"}
        >
          <Image src={logo} alt="Dan Abramov" w={"40px"} />
          <Stack direction={"row"} spacing={6}>
            {menuBottom.map((menu) => (
              <Link to={menu.url} key={menu.id}>
                {menu.name}
              </Link>
            ))}
          </Stack>
        </Container>

        <Box borderTopWidth={1} borderStyle={"solid"} borderColor={"gray.200"}>
          <Container
            as={Stack}
            maxW={"container.lg"}
            py={4}
            direction={{ base: "column", md: "row" }}
            spacing={4}
            justify={{ base: "center", md: "space-between" }}
            align={{ base: "center", md: "center" }}
          >
            <Text>&copy; {moment().format("YYYY")}. All rights reserved</Text>
            <Stack direction={"row"} spacing={4}>
              {socialMediaBottom.map((menu) => (
                <Link to={menu.url} key={menu.id}>
                  <Box
                    p={2}
                    rounded={"full"}
                    bg={"#000"}
                    color={"#fff"}
                    _hover={{
                      bg: "#fff",
                      color: `${menu.color}`,
                      textDecoration: "none",
                    }}
                  >
                    {menu.icon}
                  </Box>
                </Link>
              ))}
            </Stack>
          </Container>
        </Box>
      </Box>
    </footer>
  );
}
